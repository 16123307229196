@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;1,600;1,700&display=swap");
body {
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
}

:root {
    --brandColor: #ffc514;
    --backgroundColor: #e5e5e5;
    --acentColor: #1e1e1e;
}

.ant-table-filter-trigger {
    transform: translateY(4px) !important;
}

.anticon {
    transform: translateY(-4px) !important;
}

.status {
    margin: auto !important;
}

.ant-message .anticon {
    margin-right: 8px !important;
    color: white !important;
}

.ant-message-notice-content {
    border-radius: 10px !important;
    font-weight: 600 !important;
    padding: 0 !important;
}

.ant-message-custom-content {
    padding: 0.7rem 1rem !important;
    border-radius: 10px !important;
    color: white !important;
}

.ant-message-error {
    background: #cf3f3c !important;
}

.ant-message-success {
    background: #309442 !important;
}


/*pagination */

.ant-pagination.mini.ant-table-pagination {
    padding-top: 10px !important;
}

.ant-pagination.mini .ant-pagination-item {
    min-width: 30px !important;
    border: none !important;
    margin: auto 0.5rem !important;
}

.ant-table-pagination.ant-pagination {
    padding: 0rem 2rem 1rem 2rem !important;
}

.ant-table-footer {
    padding: 12px 0px !important;
    color: rgba(0, 0, 0, 0.85) !important;
    background: #ffffff !important;
}

.ant-pagination-item-active {
    background-color: #f5f5f5 !important;
    border: none !important;
}

.ant-pagination-item-active a {
    color: #313131 !important;
    padding: 1px !important;
    font-weight: 600 !important;
    opacity: 0.8 !important;
}

.ant-checkbox-inner {
    border: 1px solid grey !important;
    width: 14px !important;
    height: 14px !important;
}

.ant-checkbox-inner::after {
    left: 12.5% !important;
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: none;
}

* {
    margin: 0 !important;
}

code {
    font-family: "Montserrat", sans-serif;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}


/* Track  */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 10px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #555;
}


/*Sidebar scrollbar*/

.AppSiderBar::-webkit-scrollbar {
    display: none;
}

.AppSiderBar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.ant-form-vertical .ant-form-item-label {
    padding: 0 !important;
    height: 45px !important;
}

.ant-form-item {
    margin-bottom: 20px !important;
}

.ant-input {
    color: #000000 !important;
    font-size: 14px !important;
    opacity: 0.9 !important;
}

.ant-input:focus,
.ant-input-focused {
    outline: 0 !important;
    box-shadow: 0 0 0 0px transparent !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
    outline: 0 !important;
    box-shadow: 0 0 0 0px transparent !important;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #18c35d !important;
    border-color: #18c35d !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #18c35d !important;
}

.ant-switch-handle::before {
    transform: translateY(-3px) !important;
}

.ant-switch {
    height: 16px !important;
    min-width: 40px !important;
}

.ant-table-tbody>tr>td {
    font-size: 14px !important;
    line-height: 22px !important;
    font-weight: 500 !important;
    color: #1e1e1e !important;
    text-transform: capitalize !important;
    padding: 8px !important;
}

.ant-table-thead>tr>th {
    font-size: 11px !important;
    line-height: 22px !important;
    color: #1e1e1e !important;
    opacity: 0.8 !important;
}

.ant-picker {
    padding: 0.5rem 0.9rem !important;
    border-radius: 10px !important;
    color: #1e1e1e !important;
}

.ant-picker input::placeholder {
    color: #1e1e1e !important;
    font-weight: 500;
}

.ant-tag {
    margin: 2px !important;
    background-color: #e8f3fd !important;
    color: #1d8aef !important;
    border-radius: 10px !important;
    font-size: 14px !important;
    padding: 0.1rem 1rem !important;
}

.ant-tag svg {
    color: #1d8aef !important;
    transform: translate(2px, -2px);
}

.ant-select-selector {
    border: none !important;
}

table tr td.ant-table-selection-column {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
}

.ant-picker-suffix>* {
    transform: translateY(0px) !important;
}

.ant-empty-image svg {
    margin: auto !important;
}

.ant-table-thead {
    box-shadow: 0px 1px 0.1px 0px #f1f1f1 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
}

.ant-table-container table {
    padding-bottom: 0.7rem !important;
    padding-top: 0.5rem !important;
}

.ant-checkbox-group-item {
    display: block;
    margin-right: 15px !important;
}

.ant-radio-wrapper {
    margin-right: 15px !important;
}

.ant-input-number-group-wrapper {
    border-bottom: 2px solid #e6e6e6 !important;
}

.ant-input-number-group-addon,
.ant-input-group-addon {
    background-color: transparent !important;
    border: none !important;
    /* padding: 0 0 0 11px !important; */
    padding: 0px !important;
}

.addonbefore {
    border-right: 2px solid #e6e6e6 !important;
}

.ant-table-filter-column {
    display: -webkit-inline-box !important;
    display: -moz-inline-box !important;
    display: -ms-inline-box !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    left: 50% !important;
    background-color: #41c35c !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.app-container {
    overflow-y: auto;
    /* padding: 1.25rem 2.5rem; */
    padding: 0.8rem 2.5rem;
}

/* .ant-tabs-tabpane{
    height:100%;
} */

.ant-tabs-content-holder{
    overflow: auto;
}

.ant-switch>.ant-switch-handle::before {
    background-color: grey;
}

.ant-switch-checked>.ant-switch-handle::before {
    background-color: #41c35c !important;
}

.ant-modal-header,
.ant-modal-body,
.ant-modal-content {
    border-radius: 20px !important;
    border: none !important;
}

.ant-modal-close-x {
    display: grid !important;
    place-items: center;
}

.f-table-content {
    text-align: center;
    width: 100%;
}

.ant-pagination-options {
    text-transform: capitalize;
}

.ant-pagination-options .ant-select-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-transform: capitalize;
}

.ant-table-container .ant-table-summary {
    background: transparent;
}


/* DatePicker */

.ant-picker>.ant-picker-input>input {
    padding-left: 12px;
}

.ant-picker>.ant-picker-input>input,
.ant-picker>.ant-picker-input>input::placeholder {
    text-align: center;
    text-transform: capitalize;
}

.ant-picker-range {
    padding: auto 10px !important;
}

.ant-picker-range>.ant-picker-input:nth-child(1) {
    padding-left: 5px;
}

.ant-picker-range>.ant-picker-input>input,
.ant-picker-range>.ant-picker-input>input::placeholder {
    text-align: center;
    text-transform: capitalize;
}

.ant-picker.ant-picker-range.ant-picker-focused>.ant-picker-active-bar {
    display: none !important;
}

.ant-picker-suffix {
    position: absolute;
}

.ant-picker.ant-picker-range.ant-picker-clear>.anticon.anticon-close-circle {
    transition: unset;
    top: 60%;
}


/* DatePicker */

.antd-skleton.ant-skleton-element.ant-skleton-active{
    width:100%;
}

.antd-popover-inner-content{
    display: flex !important;
    flex-direction: column !important;
    gap: 20px !important;
    background-color: red;

}
.antd-popover-inner-content > .antd-popover-buttons{
    display: flex !important;
    gap:10px !important
}

